import styled from 'styled-components'

const Container = styled.main`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  min-height: ${props => props.minHeight || '100vh'};
  padding: 1em;
`

export default Container
