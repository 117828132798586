import { useState, useEffect, useCallback } from "react";
import { INITIAL_SNAKE } from "../../helpers/snake";

export const useSnake = ({ playing, score }) => {
  const [snake, setSnake] = useState(INITIAL_SNAKE);
  const [direction, setSnakeDirection] = useState({ x: 0, y: 0 })
  const speed = Math.max(0, Math.ceil(100 - score))

  const resetSnake = useCallback(() => {
    setSnake(INITIAL_SNAKE)
  }, [])

  useEffect(() => {
    if (!playing) return;

    const to = setInterval(() => {
      setSnake(s => {
        const head = {
          x: s[0].x + direction.x,
          y: s[0].y + direction.y
        }

        return [
          head,
          ...(s.length > score + 2 ? [...s].slice(0, s.length - 1) : s)
        ]
      })
    }, speed);

    return () => {
      clearTimeout(to)
    }
  }, [playing, direction, score, speed])

  return { snake, resetSnake, setSnakeDirection }
}
