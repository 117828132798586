import React from 'react';
import styled from 'styled-components';

import './polyfill/String.matchAll';

import contents from './contents.json';

import Footer from './components/Footer';
import PlainSite from './components/content/Plain';
import Device from './components/Device';
import Slider from './components/Slider';

import { useWebsiteZoom } from './hooks/useWebsiteZoom';

const Website = styled.article`
  display: flex;
  flex-direction: column;
	perspective-origin: 50% 100px;
`;

const Title = styled.h1`
  font-family: Jura, sans-serif;
  font-weight: 700;
  text-align: center;
  color: whitesmoke;

  small {
    font-weight: 400;
  }
`;

const DeviceContainer = styled.section`
  padding: 1em 0;
  transition-property: transform;
  transition-duration: 1s;
`;

const Home = props => {
  const zoom = useWebsiteZoom();

  const { mode, disableContent, perspective } = props.deviceMode;

  return (
    <>
      {mode === 'loading' ? null : (
        <Website className="website">
          {mode === 'plain' ? (
            <PlainSite
              sections={contents.sections}
              title={contents.title}
              headline={contents.headline}
            />
          ) : (
            <>
              <Title>{contents.title}: <small>{contents.subtitle}</small></Title>
              <Slider
                values={[
                  { label: (1984 - ((new Date()).getFullYear() - 1984)), value: 'newspaper' },
                  { label: '1984', value: 'desktop' },
                  { label: (new Date()).getFullYear(), value: 'tablet' }
                ]}
                initialValue={(
                  mode === 'newspaper' ? 0 :
                  mode === 'tablet' ? 2 :
                  1
                )}
                onChange={props.setDeviceMode}
              />
              <DeviceContainer
                className="device-container"
                style={{
                  perspective: `1600px`,
                  zoom
                }}
              >
                <Device
                  disableContent={disableContent}
                  contents={contents}
                  mode={mode}
                  perspective={perspective}
                />
              </DeviceContainer>
            </>
          )}

          <Footer mode={mode} />
        </Website>
      )}
    </>
  )
}

export default Home;
