import React, { useRef, useState, useCallback, useEffect } from 'react'
import styled from 'styled-components';
import {
  useParams
} from "react-router-dom";

import { decrypt } from '../../helpers/encryption'
import { success, CHECKSUM, getCh, setColors } from '../../helpers/cover'

import Container from '../Container'
import Dialog from '../Dialog'
import Footer from '../Footer';
import { useAskPassword } from '../../hooks/useAskPassword';
import { useJson } from '../../hooks/useJson'
import { onSecondFrame } from '../../helpers/helpers';
import CoverAnimationElements from './CoverAnimationElements';

const Message = styled.section`
  color: #0a0;
  cursor: ${props => props.solved ? 'default' : 'pointer'};
  font-family: monospace;
  max-width: 740px;
  white-space: pre-wrap;
  word-break: break-word;
  margin: 2em auto;
  position: relative;
  overflow: hidden;
  opacity: ${props => props.askingPassword ? 0.75 : 1};
  transition-property: opacity;
  transition-duration: 1s;
  text-align: justify;
`

const Ch = styled.span`
  display: inline-block;
  position: relative;
  text-align: center;
`

const Cover = ({ hideFooter, savedPassword, minHeight }) => {
  const [data, setData] = useState(null)
  const [message, setMessage] = useState(null)
  const [solved, setSolved] = useState(false)
  const [chars, setChars] = useState([])
  const [showAnimationElements, setShowAnimationElements] = useState(false)
  const { showAskPassword, setShowAskPassword, askPassword, passwordInput } = useAskPassword(solved)

  const container = useRef(null)

  const { hash } = useParams()

  const decode = useCallback((e) => {
    if (solved) return null

    const password = savedPassword || e.target.value
    if (!password) return

    const dc = decrypt(password)

    const validPassword = dc(data.checksum) === CHECKSUM;

    if (validPassword) {
      setShowAskPassword(false)
      setSolved(true)
      setMessage(dc(data.secret))
    }
  }, [data, solved, setShowAskPassword, savedPassword])

  const json = useJson(hash)

  const playSuccessAnimation = useCallback(() => {
    message && onSecondFrame(() => {
      setShowAnimationElements(true);
      success({
        animation: json.animation,
        container: container.current,
        message
      })
    })
  }, [message, json])

  useEffect(() => {
    if (json) {
      setData(json)

      const tokens = json && json.secret.match(/.{3}/g)
      setChars(tokens && tokens.map((ch, index) => <Ch className="ch" key={index}>{getCh(json, ch)}</Ch>))
      setColors(json);

      if (savedPassword) {
        const dc = decrypt(savedPassword)

        if (dc(json.checksum) === CHECKSUM) {
          setShowAskPassword(false)
          setSolved(true)
          setMessage(dc(json.secret))
          playSuccessAnimation(json.animation)
        }
      } else {
        setTimeout(askPassword, 750)
      }
    }
  }, [json, askPassword, decode, setShowAskPassword, playSuccessAnimation, savedPassword])

  return (
    <Container minHeight={minHeight}>
      <Dialog open={showAskPassword} onTransitionEnd={playSuccessAnimation}>
        <label>Password: <input ref={passwordInput} type="password" onKeyUp={decode} /></label>
      </Dialog>
      <Message
        ref={container}
        askingPassword={showAskPassword}
        solved={solved}
        onClick={askPassword}
      >
        {showAnimationElements && <CoverAnimationElements animation={json?.animation} />}
        {chars}
      </Message>
      {!hideFooter && <Footer mode="cover">
        and <a href="https://animejs.com/">Anime.js</a>
      </Footer>}
    </Container>
  )
}

export default Cover
