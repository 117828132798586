import { useState } from 'react'

import { useSnake } from './useSnake'
import { useFood } from './useFood'
import { useCollisions } from './useCollisions'
import { useKeyboard } from './useKeyboard'

export const useSnakeGame = () => {
  const [gameOver, setGameOver] = useState(true)
  const [pause, setPause] = useState(null)
  const [score, setScore] = useState(null)

  const playing = !gameOver && !pause

  const { snake, resetSnake, setSnakeDirection } = useSnake({ playing, score })
  const { food, resetFood } = useFood({ playing, snake, score, setScore })

  useCollisions({ playing, snake, food, setGameOver, setScore, resetFood })
  useKeyboard({ gameOver, setSnakeDirection, setPause, resetSnake, resetFood, setScore, setGameOver })

  return {
    snake,
    food,
    score,
    gameOver,
  }
}