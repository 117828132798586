import React from "react";
import {
  Routes,
  Route,
  useMatch,
} from "react-router-dom";

import AppStyle from './components/style/AppStyle';

import Home from './Home'
import CoverCreate from './components/cover/CoverCreate'
import CoverView from './components/cover/CoverView'

import Terminal from './components/terminal/Terminal'

import { useDeviceMode } from "./hooks/useDeviceMode";

const App = () => {
  const [deviceMode, setDeviceMode] = useDeviceMode({
    mode: 'loading',
    disableContent: false,
    perspective: { x: 0, y: 0 },
  });

  // we want to force the mode to "cover" when the path matches it
  const cover = !!useMatch('/cover')
  const terminal = !!useMatch('/terminal')

  return (
    <>
      <AppStyle mode={(cover || terminal) ? 'cover' : deviceMode.mode} />
      <Routes>
        <Route exact path="/cover/create" element={<CoverCreate action="create" />} />
        <Route exact path="/cover" element={<CoverCreate action="create" />} />
        <Route path="/cover/:hash" element={<CoverView />} />
        <Route path="/terminal/:hash" element={<Terminal />} />
        <Route path="*" element={<Home deviceMode={deviceMode} setDeviceMode={setDeviceMode} />} />
      </Routes>
    </>
  )
}

export default App
