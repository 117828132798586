import { useRef, useEffect } from "react";

export const useInterval = (callback, delay, restartDependency) => {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    console.log('resetting interval')
    const id = setInterval(() => {
      savedCallback.current()
    }, delay)
    return () => clearInterval(id)
  }, [delay, restartDependency]);
}
