import { useState, useCallback, useEffect } from "react"
import { randomXY } from "../../helpers/snake"
import { beep } from "../../helpers/sounds"
import { useInterval } from './useInterval'

export const useFood = ({ playing, snake, score, setScore }) => {
  const [food, setFood] = useState(null)

  const resetFood = useCallback(() => {
    setFood(randomXY(snake))
  }, [snake])

  useInterval(() => {
    if (!playing) return;

    setFood(randomXY(snake))
  }, 20000, food)

  useEffect(() => {
    if (!playing) return;

    if (snake[0].x === food.x && snake[0].y === food.y) {
      beep(440, 25)
      setScore(s => s + 1)
      resetFood()
    }
  }, [playing, snake, food, resetFood, setScore])

  return { food, resetFood }
}
