import { useEffect, useState } from "react";


export const useJson = (hash) => {
  const [json, setJson] = useState(null);

  useEffect(() => {
    (async () => {
      if (/[0-9]+/.test(hash)) {
        const response = await fetch(`/json/${hash}.json`)
        try {
          const json = await response.json()
          setJson(json)
        } catch (e) {
        }
      }
    })();
  }, [hash])

  return json;
}
