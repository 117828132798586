import { useState, useEffect, useCallback } from 'react';
import { useScreenSize } from './useScreenSize';

export const useDeviceMode = ({
  mode: initialMode,
  disableContent: initialDisableContent,
  perspective: initialPerspective
} = {}) => {
  const windowSize = useScreenSize();

  const [mode, setMode] = useState(initialMode);
  const [prevMode, setPrevMode] = useState('desktop');
  const [disableContent, setDisableContent] = useState(initialDisableContent);
  const [perspective, setPerspective] = useState(initialPerspective);

  const setDeviceMode = useCallback((chosenMode) => {
    const newMode = (
      chosenMode ? chosenMode :
        windowSize.outerWidth < 768 ? 'plain' :
      /* else */ prevMode
    );

    if (newMode === mode) return;

    if (newMode === 'desktop') {
      setMode('desktop');
      setPrevMode('desktop');
      setPerspective({ x: -15, y: -15 });
      setDisableContent(true);
      setTimeout(() => setDisableContent(false), 1000);
    } else if (newMode === 'tablet') {
      setMode('tablet');
      setPrevMode('tablet');
      setPerspective({ x: 0, y: 0 });
      setDisableContent(true);
      setTimeout(() => setDisableContent(false), 1000);
    } else if (newMode === 'newspaper') {
      setMode('newspaper');
      setPrevMode('newspaper');
      setPerspective({ x: 0, y: 0 });
      setDisableContent(true);
      setTimeout(() => setDisableContent(false), 1000);
    } else {
      setMode('plain');
      setPerspective({ x: 0, y: 0 });
      setDisableContent(true);
      setTimeout(() => setDisableContent(false), 1000);
    }
  }, [mode, prevMode, windowSize.outerWidth]);

  useEffect(setDeviceMode, [setDeviceMode, windowSize.outerWidth])

  return [{ mode, perspective, disableContent }, setDeviceMode];
};
