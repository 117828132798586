import { WIDTH, HEIGHT } from "../../helpers/snake";
import { useEffect } from "react";
import { beep } from "../../helpers/sounds";

export const useCollisions = ({ playing, snake, food, setGameOver, setScore, resetFood }) => {
  useEffect(() => {
    if (!playing) return;

    const wallHit = s => s.find(({x, y}) => (
      x < 0 || y < 0 ||
      x > (WIDTH - 1) || y > ((HEIGHT - 1))
    ))

    const snakeHit = s => s.find(({ x, y }, index) => index > 0 && x === s[0].x && y === s[0].y)

    if (wallHit(snake) || snakeHit(snake)) {
      beep(100, 200)
      setGameOver(true)
      return;
    }
  }, [playing, snake, food, resetFood, setGameOver])
}
