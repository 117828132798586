import React, { useRef, useState, useCallback, useLayoutEffect } from 'react'
import styled from 'styled-components'

import Container from '../Container'
import Footer from '../Footer'
import { encrypt } from '../../helpers/encryption'
import { useAskPassword } from '../../hooks/useAskPassword'
import Dialog from '../Dialog'
import { CHECKSUM } from '../../helpers/cover'

const Textarea = styled.textarea`
  color: white;
  background-color: #222;
  outline-color: #0a0;
  flex-grow: 1;
  font-size: 1em;
  font-family: monospace;
  margin: 1em auto;
  width: 75vw;
`

const Button = styled.button`
  background-color: #060;
  color: white;
  cursor: pointer;
  font-family: monospace;
  font-size: 1em;
  outline-color: #0a0;
  border: none;
  text-transform: uppercase;
  width: fit-content;
  margin: 0.5em auto;
  padding: 0.75em 2.25em;

  &:hover {
    background-color: #080;
  }
`

const useToast = () => {
  const [toast, setToast] = useState(null)
  const [visible, setVisible] = useState(null)

  const showToast = useCallback((msg) => {
    setToast(msg)
    setVisible(true)

    setTimeout(() => {
      setVisible(false)
    }, 3000)
  }, [])

  return [toast, visible, showToast]
}

const CoverCreate = () => {
  const textarea = useRef(null)
  const [toastMessage, toastVisible, showToast] = useToast()

  const [secret, setSecret] = useState('')
  const [encoded, setEncoded] = useState(false)

  const { showAskPassword, setShowAskPassword, askPassword, passwordInput } = useAskPassword(encoded)

  useLayoutEffect(() => {
    if (!secret || !encoded) return

    textarea.current.select()
    document.execCommand('copy')
    document.getSelection().removeAllRanges()

    showToast('Copied to clipboard!')
  }, [encoded, secret, showToast])

  const encode = useCallback(e => {
    if (!e.target.value || e.key !== 'Enter') return

    const ec = encrypt(e.target.value)

    setSecret(JSON.stringify({
      checksum: ec(CHECKSUM),
      secret: ec(textarea.current.value),
      animation: 'default',
    }, null, 2))
    setEncoded(true)
  }, [])

  const handleChange = useCallback(e => {
    setSecret(e.target.value)
  }, [])

  const handleButtonClick = useCallback(() => {
    if (!secret) return;

    if (!encoded) return askPassword();

    setShowAskPassword(false)
    setSecret('')
    setEncoded(false)
  }, [encoded, secret, askPassword, setShowAskPassword])

  return (
    <Container>
      <Dialog open={toastVisible || showAskPassword}>
        {
          showAskPassword ? <label>Password: <input ref={passwordInput} onKeyUp={encode} /></label> :
          toastMessage ? toastMessage :
          null
        }
      </Dialog>
      <Textarea
        name="secret"
        ref={textarea}
        readOnly={encoded}
        onChange={handleChange}
        value={secret}
      />
      <Button onClick={handleButtonClick}>
        {encoded ? 'Repeat' : 'Create'}
      </Button>
      <Footer mode="cover" />
    </Container>
  )
}

export default CoverCreate
