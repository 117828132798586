export const WIDTH = 30
export const HEIGHT = 18
export const SIZE = 17

export const INITIAL_SNAKE = [
  { x: 15, y: 9 },
  { x: 14, y: 9 },
  { x: 13, y: 9 },
]

export const randomXY = (snake) => {
  let position;

  const hasCollision = ({ x, y }) => x === position.x && y === position.y

  do {
    position = {
      x: Math.floor(Math.random() * WIDTH),
      y: Math.floor(Math.random() * HEIGHT),
    };
  } while(snake.some(hasCollision))

  return position;
}
