export const encrypt = s => t => t
  .split('').map((c, i) => (
    c.charCodeAt(0) + s.charCodeAt(i % s.length) + 84
  ))
  .join('')

export const decrypt = s => t => t
  .match(/.{3}/g)
  .map((chunk, i) => String.fromCharCode(+chunk - s.charCodeAt(i % s.length) - 84))
  .join('')
