import React from 'react'
import { removeTextTags } from '../../helpers/removeTextTags';
import SnakeGame from '../SnakeGame';

const DesktopComponent = (props) => {
  if (props.insertedDisk === 'X') return <SnakeGame />

  if (props.insertedDisk === -1) return (
    `\n\n\n\n\n\n\n                     ${props.title}\n                   <<< INSERT DISK >>>`
  );

  const content = props.sections[props.insertedDisk];
  const desktopTitle = `${props.title}: ${content.title}`.toUpperCase();

  return [
    desktopTitle,
    '='.repeat(desktopTitle.length),
    `\n${removeTextTags(content.text)}`,
    `\n                  <<< INSERT DISK ${props.nextDisk} >>>`
  ].join('\n');
};

export default DesktopComponent;
