const pause = to => new Promise((resolve) => setTimeout(resolve, to))

export const beep = (frequency = 440, duration = 25) => new Promise((resolve) => {
  if (!window.AudioContext) return;

  const ctx = new AudioContext()

  const gain = ctx.createGain()
  gain.gain.value = 0.1
  gain.connect(ctx.destination)

  const osc = ctx.createOscillator()
  osc.frequency.setValueAtTime(frequency, ctx.currentTime)
  osc.type = 'square'
  osc.connect(gain)
  osc.start()
  setTimeout(() => { osc.stop(); resolve(); }, duration)
});

export const intro = async () => {
  await beep(262)
  await pause(100)
  await beep(330)
  await pause(100)
  await beep(392)
  await pause(100)
  await beep(523)
}