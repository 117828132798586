import React, { useRef, useState, useEffect, useCallback } from 'react'
import styled from 'styled-components';

import {
  useParams,
} from "react-router-dom";
import { useJson } from '../../hooks/useJson'

import CoverView from '../cover/CoverView'


import { pulseTextAnimation } from '../../helpers/animations';
import { CHECKSUM } from '../../helpers/cover'

import Container from '../Container'
import Footer from '../Footer';
import { decrypt } from '../../helpers/encryption';

const Row = styled.div`
  display: flex;
`

const Result = styled.div`
  white-space: pre-wrap;
`

const TerminalScreen = styled.article`
  animation: ${pulseTextAnimation('color', 'green', 'limegreen')} 2s infinite alternate;
  font-family: 'Source Code Pro', monospace;
  white-space: pre-wrap;
  color: limegreen;
  margin: 0 auto;
  background-color: #111;
  border: 1px solid gray;
  border-radius: 0.25em;
  padding: 0.5em;
  width: 80vw;
  height: 80vh;
  min-height: 300px;
  overflow-y: auto;
`

const TerminalInput = styled.input`
  border: 0;
  background: transparent;
  color: limegreen;
  font-family: 'Source Code Pro', monospace;
  font-size: 1em;
  padding: 0;
  width: 100%;

  &:focus {
    outline: none;
  }
`

const Path = styled.span`
  color: green;
  margin-right: 0.5em;
`

const date = new Date()
const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

const month = months[date.getMonth()]
const day = date.getDate()

const encFiles = [
  {
    name: 'experiments',
    size: 0,
    perms: 'dr-x------',
    month,
    day,
    files: [
      {
        name: 'README',
        content: 'This folder contains some experiments',
        size: 'This folder contains some experiments'.length,
        perms: '-r--------',
        month,
        day,
      },
      {
        name: 'animate',
        size: 100,
        perms: '-r-x------',
        month,
        day,
        content: (savedPassword) => {
          return <CoverView minHeight="auto" savedPassword={savedPassword} hideFooter />
        }
      }
    ]
  },
  {
    name: 'cover.txt',
    content: CHECKSUM,
    size: 0,
    perms: '-r--------',
    month,
    day,
  }
]

const gibberish = (c = 40, r = 10) => {
  return [...Array(r)].map(() => (
    [...Array(c)].map(() => String.fromCharCode(Math.floor(Math.random() * 57) + 65)).join('')
  )).join('\n')
}

const Terminal = (props) => {
  const { hash } = useParams()
  const [savedPassword, setSavedPassword] = useState(null)

  const [hideWelcome, setHideWelcome] = useState(false)
  const [files, setFiles] = useState(encFiles)
  const [logged, setLogged] = useState(false)
  const [user, setUser] = useState(null)
  const [history, setHistory] = useState([
    ['', '', ''],
  ])
  const [pos, setPos] = useState(0)
  const [path, setPath] = useState([])

  const container = useRef(null)
  const inputRef = useRef(null)

  const fullPath = path.join('/')

  const json = useJson(hash)

  //
  // history
  //

  const clearHistory = () => {
    setHistory([['', '', '']])
    setHideWelcome(true)
  }

  const addHistory = useCallback((command, result) => {
    const newHistory = [...history]
    newHistory.pop()

    setHistory([
      ...newHistory,
      [fullPath, command, result || ''],
      [fullPath, '', '']
    ])
  }, [history, fullPath])


  //
  // commands
  //

  const fixPath = (pathToFix) => {
    return pathToFix.reduce((acc, dir) => {
      return (
        !acc ? null :
        dir === '.' ? acc :
        dir !== '..' ? [...acc, dir] :
        acc.length ? acc.slice(0, acc.length - 1) :
        null
      )
    }, [])
  }

  const getFinalPath = useCallback((filepath) => {
    const parts = filepath.split('/')
    const filename = parts.pop()

    return [fixPath([...path, ...parts]), filename]
  }, [path])

  const getFilesInPath = useCallback((pathStr) => {
    return (
      !pathStr ? files :
      (pathStr.split('/')).reduce((pwd, dir) => {
        if (!pwd) return null
        const subdir = pwd.find(f => f.name === dir && f.perms[0] === 'd')
        return subdir ? subdir.files : null
      }, files)
    )
  }, [files])

  const filesInPath = getFilesInPath(fullPath)

  const ls = useCallback((params) => {
    const allFiles = params.includes('-a') ? filesInPath.concat([
      {
        name: '.',
        size: 0,
        hidden: true,
        perms: 'dr--------',
        month,
        day,
      },
      {
        name: '..',
        size: 0,
        hidden: true,
        perms: 'dr--------',
        month,
        day,
      }
    ]) : filesInPath.filter(f => !f.hidden)

    const printFileType = f => (
      isExecutable(f) ? '*' :
      isDirectory(f) ? '/' :
      ''
    )

    const printFiles = () => {
      return (
        params.includes('-l')
          ? `total: ${allFiles.reduce((acc, f) => acc + f.size, 0)}
${allFiles.map(f => `${f.perms}\tdavide\troot\t${f.size}\t${f.month}\t${f.day}\t${f.name}${printFileType(f)}`).join('\n')}` :
        allFiles.map(f => `${f.name}${printFileType(f)}`).join('\t')
      )
    }

    return printFiles()
  }, [filesInPath])

  const cd = useCallback((params) => {
    const param = params[params.length - 1]

    const nextPath = fixPath([...path, ...(param || '').split('/')])

    if (!nextPath) return `cd: permission denied: ${param}`

    if (getFilesInPath(nextPath.join('/'))) setPath(nextPath)
    else return `cd: no such file or directory: ${param}`
  }, [path, getFilesInPath])

  const cat = useCallback(([filepath = '']) => {
    const [finalPath, filename] = getFinalPath(filepath)
    const filesInDir = getFilesInPath(finalPath.join('/'))

    const file = (filesInDir || []).find(f => f.name === filename)

    return `${
      !file ? `cat: ${filename}: No such file or directory` :
      isDirectory(file) ? `cat: ${filename}: Is a directory` :
      typeof file.content !== 'string' ? gibberish() :
      /* else */ file.content
    }`
  }, [getFilesInPath, getFinalPath])

  const cp = useCallback((params, cmd) => {
    return (params.length < 2)
      ? `usage: ${cmd} [-R [-H | -L | -P]] [-fi | -n] [-apvXc] source_file target_file\n`
      : `${cmd}: ${params[1]}: Permission denied`
  }, [])

  const help = useCallback((cmd) => {
    addHistory(cmd, `Available commands:
cat
  See the content of a file

cd
  Change directory

clear
  Clear the console

cp
  Copy files

help, ?
  Print out this help message

logout, exit
  Go back to the login screen

ls
  Show the files in the current directory

mv
  Move files

pwd
  Print out the current working directory

rm
  Remove files

sudo
  Run commands with superpowers

whoami
  Print out the current logged user name

`)
  }, [addHistory])

  //
  // login
  //

  const login = useCallback((password) => {
    const remapFiles = (curFiles) => {
      return curFiles.map(f => {
        if (!f.content) return f

        const content = f.content === CHECKSUM ? dc(json.secret) : f.content
        const size = content.length

        const files = (Array.isArray(f.files))
          ? remapFiles(f.files)
          : f.files

        return { ...f, content, size, files }
      })
    }

    const dc = decrypt(password)
    if (dc(json.checksum) === CHECKSUM) {
      setSavedPassword(password)
      const newFiles = remapFiles(files)

      setFiles(newFiles)
      setLogged(true)
    } else {
      setUser(null)
    }
    setHistory([['', '', '']])
  }, [files, json])

  const logout = useCallback(() => {
    clearHistory()
    setUser(null)
    setLogged(false)
    setFiles(encFiles)
    setHideWelcome(false)
    setSavedPassword(null)
  }, [])

  const isExecutable = file => (
    file &&
    file.perms[0] === '-' &&
    file.perms[3] === 'x' &&
    typeof file.content === 'function'
  )

  const isDirectory = file => (
    file &&
    file.perms[0] === 'd'
  )

  const execFile = useCallback((cmd, params) => {
    if (/^\.\//.test(cmd)) {
      const [finalPath, filename] = getFinalPath(cmd)
      const filesInDir = getFilesInPath(finalPath.join('/'))

      const file = (filesInDir || []).find(f => f.name === filename)

      if (isExecutable(file)) {
        return file.content(savedPassword, params)
      }
    }

    return `command not found: ${cmd}\n`
  }, [getFilesInPath, getFinalPath, savedPassword])


  const execCommand = useCallback((command) => {
    const [cmd, ...cmdParams] = command.split(' ')
    const params = cmdParams.reduce((acc, cmdParam) => {
      return acc.concat(
        (cmdParam[0] === '-' && cmdParam.length > 1) ? cmdParam.split('').splice(1).map(x => `-${x}`) : cmdParam
      )
    }, [])

    switch (cmd) {
      case 'cat': return addHistory(command, cat(params))

      case 'cd': return addHistory(command, cd(params))

      case 'clear': return clearHistory()

      case 'ls': return addHistory(command, ls(params))

      case 'whoami': return addHistory(command, user)

      case 'pwd': return addHistory(command, `/home/${user}/${fullPath}`)

      case 'sudo': return addHistory(command, `${user} is not in the sudoers file. This incident will (not) be reported.`)

      case 'cp':
      case 'mv': return addHistory(command, cp(params, cmd))

      case 'rm': return addHistory(command, `${cmd}: permission denied: ${params[params.length - 1]}`)

      case 'logout':
      case 'exit': return logout()

      case 'help':
      case '?': return help(cmd)

      case '': return addHistory(command)

      default: addHistory(command, execFile(cmd, params))
    }
  }, [cat, cd, cp, ls, help, logout, user, fullPath, addHistory, execFile])


  //
  // keyboard
  //

  const handleKeyDown = useCallback((e) => {
    const lastCommand = e.target.value.trim()

    switch (e.key) {
      case 'ArrowUp': {
        e.preventDefault();
        setPos(prevPos => Math.max(0, prevPos - 1))
        return
      }

      case 'ArrowDown': {
        e.preventDefault();
        setPos(prevPos => Math.min(history.length, prevPos + 1))
        return
      }

      case 'Enter': {
        if (!logged && !user && lastCommand) {
          setUser(lastCommand)
          setHistory([
            [fullPath, lastCommand, ''],
            [fullPath, '', '']
          ])
        } else if (!logged) {
          login(lastCommand)
        } else {
          execCommand(lastCommand)
        }

        return
      }

      case 'Tab': {
        e.preventDefault();

        setHistory(prevHistory => {
          const newHistory = [...prevHistory]
          const [lastFullPath, lastCommand] = newHistory.pop()

          const [cmd, ...params] = lastCommand.split(' ')
          const param = params.pop() || ''

          const [finalPath, filename] = getFinalPath(param)

          const filesInDir = getFilesInPath(finalPath.join('/'))

          const file = param && filesInDir.find(f => {
            const re = new RegExp(`^${filename.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&')}`)
            return re.exec(f.name)
          })

          const parts = param.split('/')
          const fileFullPath = file && [...parts.slice(0, parts.length - 1), file.name]

          return file
            ? newHistory.concat([
              [lastFullPath, `${cmd} ${params.length ? `${ params.join(' ')} ` : ''}${fileFullPath.join('/')}`, '']
            ])
            : prevHistory
        })

        return
      }

      case 'c': {
        if (e.ctrlKey) {
          e.preventDefault();
          addHistory(history[history.length - 1][1])
        }
        return
      }

      case 'k': {
        if (e.metaKey) {
          e.preventDefault();
          clearHistory()
        }
        return
      }

      default:
    }
  }, [history, addHistory, getFilesInPath, getFinalPath, fullPath, execCommand, user, logged, login])

  const handleInput = useCallback((e) => {
    const newHistory = [...history]
    const [lastFullPath, lastCommand] = newHistory.pop()

    switch (e.key) {
      case 'Enter': {
        if (!logged && !user && lastCommand) {
          setUser(lastCommand)
          setHistory([
            [lastFullPath, lastCommand, ''],
            [fullPath, '']
          ])
        } else if (!logged) {
          login(lastCommand)
        } else {
          execCommand(lastCommand)
        }

        return
      }

      default: {
        setHistory(newHistory.concat([
          [fullPath, `${e.target.value}`, '']
        ]))
      }
    }
  }, [history, logged, user, login, execCommand, fullPath])


  //
  // effects
  //

  const focusInput = () => {
    inputRef.current && inputRef.current.focus();
  }

  useEffect(() => {
    const containerRef = container.current

    containerRef.addEventListener('click', focusInput)

    return () => {
      containerRef.removeEventListener('click', focusInput)
    }
  }, [])

  useEffect(() => {
    if (history[history.length - 1][1] === '') setPos(history.length - 1)
    container.current.scrollTo(0, container.current.scrollHeight)
    focusInput()
  }, [history])

  useEffect(() => {
    setHistory(prevHistory => {
      const [prevFullPath, prevCommand] = ((pos >= prevHistory.length - 1) ? ['', ''] : (prevHistory[pos] || ['', '']))

      const newHistory = [...prevHistory]
      newHistory[newHistory.length - 1] = [prevFullPath, prevCommand.split('\n')[0], '']

      return newHistory
    })
  }, [pos])

  const currentCommand = history[history.length - 1][1]

  return (
    <Container>
      <TerminalScreen
        ref={container}
      >
        {!hideWelcome ? `
#   #  #####  ##         #
#   #    #    ##      #   #
#####    #    ##          #
#   #    #            #   #
#   #  #####  ##         #

` : ''}

        {history.map(([pwd, cmd, result], index) => {
          const isPassword = !logged && index
          const isCurrent = index === history.length - 1

          return (
            <React.Fragment key={index}>
              <Row>
                <Path>{
                  (logged && isCurrent) ? `${fullPath}$` :
                  logged ? `${pwd}$` :
                  isPassword ? 'password:' :
                  'login:'
                }</Path>
                {isCurrent ? (
                  <TerminalInput
                    autoCapitalize="none"
                    ref={inputRef}
                    value={currentCommand}
                    type={!logged && history.length > 1 ? 'password' : 'text'}

                    onInput={handleInput}
                    onKeyDown={handleKeyDown}
                  />
                ) : cmd}
              </Row>
              <Result>{Array.isArray(result) ? result.join('\n') : result}</Result>
            </React.Fragment>
          )
        })}
      </TerminalScreen>
      <Footer mode="cover" />
    </Container>
  )
}

export default Terminal
