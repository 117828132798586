import React from 'react'
import styled from 'styled-components';
import { rotateAnimation } from '../../helpers/animations';

const RadarPanel = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: repeating-radial-gradient(black 0, rgba(255, 255, 255, 0.05) 2px, black 4px, black 90px);
`

const Radar = styled.div`
  animation-name: ${rotateAnimation};
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  transform-origin: top left;
  position: absolute;
  top: 50%;
  left: 50%;
  border-top: 250px solid transparent;
  border-right: 200px solid transparent;
  border-bottom: 250px solid rgba(255, 255, 255, 0.05);
  border-left: 200px solid rgba(255, 255, 255, 0.05);
`

const animationElements = {
  radar: (
    <RadarPanel className="radar">
      <Radar />
    </RadarPanel>
  )
}

const CoverAnimationElements = ({ animation }) => {
  return animationElements[animation] || null;
}

export default CoverAnimationElements;
