import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';

import Screen from './Screen';
import Drive from './content/desktop/Drive';
import Disk from './content/desktop/Disk';
import Newspaper from './content/Newspaper';

import { useMousePosition } from '../hooks/useMousePosition';
import Desktop from './content/Desktop';
import Tablet from './content/Tablet';

const Device = styled.section`
  transform-style: preserve-3d;
  transform-origin: center center;
  transition-property: ${props => props.dragging ? 'none' : 'transform'};
  transition-duration: 1s;
  max-width: 100%;
  width: ${props => props.mode === 'plain' ? '100%' : '42em'};
  margin: auto;
`;

const DisksContainer = styled.section`
  visibility: ${props => props.mode !== 'desktop' ? 'hidden' : 'visible'};
  transition-property: visibility;
  transition-delay: ${props => props.mode !== 'desktop' ? 0 : 1}s;
  transform-style: preserve-3d;
  height: ${props => props.mode === 'tablet' ? 7.5 : 15}em;

  & > * {
    position: absolute;
  }
`;

const DeviceComponent = (props) => {
  const { mode, contents, disableContent } = props;
  const { title, headline, website, sections } = contents;

  const position = useMousePosition({ fps: 60 });
  const [dragging, setDragging] = useState(false);
  const [initialPosition, setInitialPosition] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState({ x: -5, y: -15 });

  const [selectedDisk, setSelectedDisk] = useState(-1);
  const [insertedDisk, setInsertedDisk] = useState(-1);

  const updateInsertedDisk = useCallback((dd) => {
    setRotation({ x: -15, y: -15 })
    setInsertedDisk(dd)
  }, [setRotation, setInsertedDisk])

  const onMouseDown = useCallback((e) => {
    if (mode !== 'desktop' || e.button !== 0) return;
    e.preventDefault();
    e.stopPropagation();

    setDragging(true);
    setInitialPosition({
      x: position.x,
      y: position.y,
      rx: rotation.x,
      ry: rotation.y,
    });
  }, [mode, position, rotation]);

  useEffect(() => {
    const removeDragging = () => setDragging(false);

    window.addEventListener('mouseup', removeDragging);

    return () => {
      window.removeEventListener('mouseup', removeDragging);
    }
  }, []);

  useEffect(() => {
    setRotation(props.perspective);
  }, [props.perspective]);

  useEffect(() => {
    if (dragging && mode === 'desktop') {
      setRotation({
        x: -position.y + initialPosition.y + initialPosition.rx,
        y: position.x - initialPosition.x + initialPosition.ry,
      });
    }
  }, [dragging, position, initialPosition, mode]);

  const content = (
    mode === 'desktop' ? (
      <Desktop
        title={title}
        headline={headline}
        website={website}
        insertedDisk={insertedDisk}
        nextDisk={((insertedDisk + 1) % sections.length) + 1}
        sections={sections}
      />
    ) :
    mode === 'newspaper' ? (
      <Newspaper
        title={title}
        headline={headline}
        website={website}
        sections={sections}
      />
    ) :
    mode === 'tablet' ? (
      <Tablet
        title={title}
        headline={headline}
        website={website}
        sections={sections}
      />
    ) :
    null
  );

  return (
    <Device
      className="device"
      dragging={dragging}
      mode={mode}
      style={{
        transform: `rotateX(${rotation.x}deg) rotateY(${rotation.y}deg)`,
      }}

      onMouseDown={onMouseDown}
    >
      <Screen
        content={content}
        disableContent={disableContent}
        mode={mode}
        insertedDisk={insertedDisk}
        selectedDisk={selectedDisk}
      />

      <Drive
        insertedDisk={insertedDisk}
        selectedDisk={selectedDisk}
        mode={mode}
        onClick={setSelectedDisk}
      />
      <DisksContainer
        className="device__disk-container"
        mode={mode}
      >
         <Disk
          key="snake"

          animation={(
            selectedDisk === 'X' && insertedDisk === -1 ? 'insert' :
            insertedDisk === 'X' && selectedDisk !== -1 ? 'eject' :
            insertedDisk === 'X' ? 'insert' :
            null
          )}

          color="#222"
          contrastColor="white"

          mode={mode}
          index="X"
          nextIndex={selectedDisk !== 'X' ? selectedDisk : -1}
          width="16em"
          height="16em"
          depth="0.5em"
          position={{
            x: '2em',
            y: '-5.5em',
            z: `-5.5em`,
          }}

          rotation={{
            x: 90,
            y: 0,
            z: 0
          }}

          title="Snake"

          onClick={setSelectedDisk}
          onAnimationEnd={updateInsertedDisk}
        />
        {sections.map((disk, index, disks) => {
          return (
            (
              <Disk
                {...disk}

                key={disk.title}

                animation={(
                  selectedDisk === index && insertedDisk === -1 ? 'insert' :
                  insertedDisk === index && selectedDisk !== -1 ? 'eject' :
                  insertedDisk === index ? 'insert' :
                  null
                )}

                mode={mode}
                index={index}
                nextIndex={selectedDisk !== index ? selectedDisk : -1}

                width="16em"
                height="16em"
                depth="0.5em"

                position={{
                  x: `${8 + (4 * index)}em`,
                  y: `${-6 - (index * 0.5)}em`,
                  z: `16em`,
                }}

                rotation={{
                  x: 90,
                  y: 5,
                  z: 0
                }}

                onClick={setSelectedDisk}
                onAnimationEnd={updateInsertedDisk}
              />
            )
          );
        })}
      </DisksContainer>
    </Device>
  );
};

export default DeviceComponent;
