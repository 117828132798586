import { useState, useRef, useEffect, useCallback } from 'react'

export const useAskPassword = (hidePasswordDialog) => {
  const [showAskPassword, setShowAskPassword] = useState(false)
  const passwordInput = useRef(null)

  useEffect(() => {
    const handleEscape = e => {
      if (e.key === 'Escape') {
        setShowAskPassword(false)
      }
    }

    document.addEventListener('keyup', handleEscape)

    return () => document.removeEventListener('keyup', handleEscape)
  }, [])

  const askPassword = useCallback(() => {
    setShowAskPassword(true)
  }, [])

  const askingPassword = showAskPassword && !hidePasswordDialog

  useEffect(() => {
    if (askingPassword) passwordInput.current.focus()
  }, [askingPassword, passwordInput])

  return { showAskPassword: askingPassword, setShowAskPassword, askPassword, passwordInput }
}
