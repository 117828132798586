import anime from 'animejs'

const getTargetsParams = (container) => {
  const targets = document.querySelectorAll('.ch')
  const [chWidth, chHeight] = [targets[0].offsetWidth, targets[0].offsetHeight]

  const grid = [
    Math.floor(container.clientWidth / chWidth),
    Math.floor(container.clientHeight / chHeight)
  ]

  return { targets, chWidth, chHeight, grid }
}

export const CHECKSUM = '!thesecretisvalid!'

export const error = ({ container }) => {
  const { targets, grid } = getTargetsParams(container)

  return anime.timeline({
    targets,
    easing: 'easeInOutSine',
    delay: anime.stagger(50)
  })
    .add({
      color: '#c00',
      duration: 250,
      delay: anime.stagger(10, { grid, from: grid[0] / 2 })
    })
    .add({
      color: '#080',
      duration: 250,
      delay: anime.stagger(10, { grid, from: targets.length - (grid[0] / 2) })
    })
}

const animations = {
  airplane: ({ container, message }) => {
        
    container.innerHTML = '';

    const airplane = document.createElement('div');
    
    airplane.style.background = 'white';
    airplane.style.width = '200px';
    airplane.style.height = '50px';
    airplane.style.borderRadius = '400px 400px 200px 200px';
    airplane.style.position = 'relative';
    airplane.style.transform = 'scale(0.1) translateY(150%)';
    airplane.style.transition = 'transform 7s cubic-bezier(0.5, 1, 0.75, 1.5) 0s';
    airplane.style.margin = '0 auto 50px auto';
    
    const wings = document.createElement('div')
    wings.style.position = 'absolute';
    wings.style.top = '50%';
    wings.style.left = '-200px';
    wings.style.width = '600px';
    wings.style.height = '12px';
    wings.style.background = 'white';
    wings.style.borderRadius = '10000px';

    airplane.appendChild(wings);

    container.appendChild(airplane)
    container.style.overflow = 'hidden';

    const targets = message.split('').map((c, i, chars) => {
      const span = document.createElement('span');
      span.style.display = 'inline-block';
      span.className = 'ch';

      if (c === '\n') {
        span.style.display = 'block';
        if (chars[i-1] === '\n') span.style.height = '1em';
      } else {
        span.innerText = c;
      }
      span.style.transform = 'translateY(-1000px)';
      span.style.opacity = '0';

      container.appendChild(span);

      return span;
    })

    targets.sort((a, b) => Math.random() < 0.5 ? 1 : -1);

    setTimeout(() => {
      airplane.style.transform = 'scale(3) translateY(-300%)';
    });

    return anime
      .timeline({
        targets,
        easing: 'easeInOutSine',
      })
      .add({
        delay: 7500
      })
      .add({
        duration: 1000,
        translateY: 0,
        opacity: 1,
        delay: anime.stagger(3, { from: 'center', axis: 'y' }),
      })
  },

  radar: ({ container, message }) => {
    const { targets, grid } = getTargetsParams(container)

    const from = 'center'

    const chars = message.split('')

    const replace = (index) => {
      const ch = chars[index]

      targets[index].innerText = ch
      if (ch === '\n') {
        targets[index].style.display = 'block'
        targets[index].style.height = '0.75em'
      }
    }

    chars.forEach((_, i) => replace(i))

    return anime.timeline({
      targets,
    })
      .add({
        opacity: 0,
        duration: 0,
        delay: 1500,
      })
      .add({
        color: '#fff',
        opacity: 1,
        easing: 'easeOutCirc',
        delay: anime.stagger(100, { grid, from }),
      })
      .add({
        delay: 2000,
        complete: () => {
          document.querySelector('.radar').firstChild.style.animationPlayState = 'paused'
        }
      })
  },

  default: ({ container, message }) => {
    const { targets, grid, chWidth } = getTargetsParams(container)

    const from = 'center'

    const chars = message.split('')

    const replace = (index) => {
      const ch = chars[index]

      targets[index].innerText = ch
      if (ch === '\n') {
        targets[index].style.display = 'block';
      }
    }

    return anime.timeline({
      targets,
      easing: 'easeInOutSine',
      delay: anime.stagger(50)
    })
      .add({
        translateX: [
          { value: anime.stagger('-.1rem', { grid: grid, from, axis: 'x' }) },
          { value: anime.stagger('.1rem', { grid: grid, from, axis: 'x' }) }
        ],
        translateY: [
          { value: anime.stagger('-.1rem', { grid: grid, from, axis: 'y' }) },
          { value: anime.stagger('.1rem', { grid: grid, from, axis: 'y' }) }
        ],
        scale: [
          { value: anime.stagger([1, 1.5], { grid, from }) }
        ],
        duration: 1000,
        delay: anime.stagger(100, { grid, from })
      })
      .add({
        translateX: 0,
        translateY: 0,
        scale: 1,
        width: Math.ceil(chWidth / 3),
        opacity: 0.25,
        duration: 1000,
        delay: anime.stagger(100, { grid, from }),
        update: () => {
          const amount = targets.length / 20
          const from = Math.floor(Math.random() * (targets.length - amount))

          for (let i = from; i < from + amount; i++) replace(i)
        },
        complete: () => {
          chars.forEach((_, i) => replace(i))
        }
      })
      .add({
        opacity: 1,
        duration: 500,
        delay: anime.stagger(50, { grid, from }),
        complete: () => {
          container.innerText = message
        }
      })
  }
}

export const success = ({ animation = 'default', container, message }) => {
  const animationFunction = animations[animation] || animations.default;

  return animationFunction({ container, message });
}

export const getCh = (json, ch) => {
  const chars = {
    airplane: () => '   ',
    radar: () => '   ',
    default: c => c,
  }

  return (chars[json?.animation] || chars.default)(ch);
}

export const setColors = json => {
  const colors = {
    airplane: ['#327db6', 'white'],
    radar: ['black', 'white'],
    default: ['black', '#0a0'],
  }

  const [background, color] = colors[json?.animation] || colors.default

  const style = document.createElement('style');
  style.innerHTML = `
      body { background-color: ${background}; }
      .ch { color: ${color}; }`

  document.head.appendChild(style)
}