import styled from 'styled-components'
import { bounceTimingFunction } from '../helpers/animations'

const Dialog = styled.dialog`
  border-radius: 8px;
  font-family: monospace;
  z-index: 1;
  will-change: top, transform;
  transition-duration: 0.25s;
  transition-property: top, transform;
  transition-timing-function: ${bounceTimingFunction};

  input {
    font-family: monospace;
    font-size: 16px;
    outline-color: #0a0;
  }

  &:not([open]) {
    display: block;
    transform: translateY(-100%);
    top: 0;
  }
`

export default Dialog
