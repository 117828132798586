import React from 'react'
import styled, { css } from 'styled-components'

import { SIZE, WIDTH, HEIGHT } from '../helpers/snake'
import { useSnakeGame } from '../hooks/snake/useSnakeGame'
import { pulseTextAnimation } from '../helpers/animations'

const SnakeGame = styled.div`${props => css`
  animation: ${pulseTextAnimation('color', 'green', 'limegreen')} 2s infinite alternate;
  background-color: black;
  border: 1px solid gray;
  color: green;
  display: flex;
  font-family: 'Press Start 2P', monospace;
  width: ${props.size * WIDTH}px;
  height: ${props.size * HEIGHT}px;
  position: relative;
  overflow: hidden;
`}`

const Snake = styled.div`${props => css`
  background-color: green;
  border: 1px solid black;
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  width: ${props.size}px;
  height: ${props.size}px;

  &.snake__head {
    background-color: limegreen;
  }
`}`

const Food = styled.div`${props => css`
  animation: ${pulseTextAnimation('background-color', 'green', 'limegreen')} 2s infinite alternate;
  background-color: green;
  background-image:
    linear-gradient(90deg, transparent 0, transparent 1px, black 2px),
    linear-gradient(transparent 0, transparent 1px, black 2px);
  background-size: 2px 2px;
  border: 2px solid black;
  box-sizing: border-box;
  position: absolute;
  top: ${props.y * props.size}px;
  left: ${props.x * props.size}px;
  width: ${props.size}px;
  height: ${props.size}px;
`}`

const GameOver = styled.h1`${props => css`
  font-size: 3em;
  margin: auto;
  text-align: center;

  ${typeof props.score === 'number' ? css`
    &::before {
      content: "score: ${props.score}";
      display: block;
      font-size: 0.25em;
      margin: 1em;
    }
  ` : ''}

  &::after {
    content: "press ENTER to play";
    display: block;
    font-size: 0.25em;
    margin: 1em;
  }
`}`

const SnakeGameComponent = props => {
  const {
    snake,
    food,
    score,
    gameOver,
  } = useSnakeGame()

  return (
    <SnakeGame
      className="snake"
      size={SIZE}
    >
      {gameOver ? (
        <GameOver score={score}>
          {typeof score === 'number' ? 'GAME OVER' : 'SNAKE'}
        </GameOver>
      ) : (
        <>
          {food && <Food className="snake__food" {...food} size={SIZE} />}
          {snake.map((sp, index) => (
            <Snake
              className={index === 0 ? 'snake__head' : 'snake__body'}
              size={SIZE}
              style={{
                transform: `translate3d(${sp.x * SIZE}px, ${sp.y * SIZE}px, 0)`
              }}
            />
          ))}
        </>
      )}
    </SnakeGame>
  )
}

export default SnakeGameComponent
