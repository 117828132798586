import { useEffect, useCallback } from "react";
import { intro } from '../../helpers/sounds'

export const useKeyboard = ({
  gameOver,
  setSnakeDirection,
  setPause,

  resetSnake,
  resetFood,
  setScore,
  setGameOver
}) => {
  const resetGame = useCallback(() => {
    resetSnake();
    setSnakeDirection({ x: 1, y: 0 })
    resetFood()
    setScore(0);

    setPause(false);
    setGameOver(false)

    intro()
  }, [setPause, setSnakeDirection, resetSnake, resetFood, setScore, setGameOver])

  useEffect(() => {
    const handleKey = e => {
      e.preventDefault();
      e.stopPropagation();

      switch (e.key) {
        case 'ArrowUp': setSnakeDirection(d => d.y ? d : { x: 0, y: -1 }); break;
        case 'ArrowDown': setSnakeDirection(d => d.y ? d : { x: 0, y: 1 }); break;
        case 'ArrowLeft': setSnakeDirection(d => d.x ? d : { x: -1, y: 0 }); break;
        case 'ArrowRight': setSnakeDirection(d => d.x ? d : { x: 1, y: 0 }); break;
        case 'Enter': gameOver ? resetGame() : setPause(p => !p); break;
        default:
      }
    }

    window.addEventListener('keydown', handleKey)

    return () => {
      window.removeEventListener('keydown', handleKey)
    }
  }, [gameOver, resetGame, setSnakeDirection, setPause])
}